(function ($, window, ccg) {

    let
        stickySidebar,
        df,
        dfTop,
        dfBottom,
        stickyPoint,
        adminbarHeight = 0,
        menuHeight = 0,
        contentBottom,
        content;

    const
        win = $(window);

    win.on("load", function () {

        // Execute on load
        checkSticky();

        // Refresh whenever window is resized.
        // noinspection JSCheckFunctionSignatures,TypeScriptUMDGlobal
        win.on('resize.sticky', null, null, ccg.debounce(checkSticky, 500));

    });

    function checkSticky() {

        // if no sticky, get out
        stickySidebar = $('.sticky-sidebar');

        if (stickySidebar.length !== 1) {
            return;
        }

        const breakpoint = ccg.getCurrentBreakpoint();

        // only on desktop
        if (breakpoint !== 'lg' && breakpoint !== 'xl') {
            // win.off('.sticky');
            stickySidebar.removeAttr('style');
            return;
        }

        // if no df, get out
        df = $('.qdf-float');

        if (df.length !== 1) {
            return;
        }

        // get the last element in the sidebar, so we know where to stick the df
        let lastInSidebar = $('.sidebar > *').last();

        if (lastInSidebar.length !== 1) {
            return;
        }

        // get the last element in the sidebars position from the top
        let lastInSidebarPosition = lastInSidebar.offset().top;

        // get the last element in the sidebars's height
        let lastInSidebarHeight = lastInSidebar.outerHeight();

        // got to have position and height of last element to know where to stick df
        if (!lastInSidebarPosition || !lastInSidebarHeight) {
            return;
        }

        // see if these are there
        dfTop = $('.qdf-top').length === 1;
        dfBottom = $('.qdf-bottom').length === 1;

        if (!dfTop) {
            df.addClass('d-lg-block')
        } else {
            df.removeClass('d-lg-block')
        }

        // get the admin bar height
        let adminbar = $('#wpadminbar');

        if (adminbar.length === 1) {
            adminbarHeight = adminbar.outerHeight();
        }

        // get the admin bar height
        let menu = $('.mainmenu');

        if (menu.length === 1) {
            menuHeight = menu.outerHeight();
        }

        // so the sticky point is when everything is the bottom of the sidebar
        stickyPoint = lastInSidebarPosition + lastInSidebarHeight - adminbarHeight;

        // get out if content is too short
        content = $('.content.with-sidebar');

        if (content.length > 0) {
            contentBottom = content.outerHeight() + content.offset().top;
        }

        if (contentBottom < stickyPoint + win.height()) {
            return;
        }

        // start with unstuck
        stickySidebar.addClass('unstuck');

        // do it initialy
        doStickiness();


        // do it on scroll
        // noinspection JSCheckFunctionSignatures,TypeScriptUMDGlobal
        win.on('scroll.sticky', null, null, ccg.throttle(doStickiness, 500));

    }

    // make it sticky or not
    function doStickiness() {

        // get the current scroll position
        let scrollPosition = win.scrollTop();
        contentBottom = Math.round(content.outerHeight() + content.offset().top - 560);

        // if we have scrolled past the sticky point, and it's not already stuck
        if (
            scrollPosition > stickyPoint &&
            scrollPosition < contentBottom &&
            stickySidebar.hasClass('unstuck')
        ) {
            stick(scrollPosition);
        }

        // if we've scrolled up past the sticky point, unstick
        if (
            scrollPosition < stickyPoint &&
            stickySidebar.hasClass('stuck')
        ) {
            unstick(scrollPosition);
        }

        // if we've scrolled down to the bottom and DF is already there, unstick
        if (
            dfBottom &&
            scrollPosition >= contentBottom &&
            stickySidebar.hasClass('stuck')
        ) {
            unstick(scrollPosition);
        }

    }

    function stick() {

        df.addClass('d-lg-block')

        // get initial width
        let width = stickySidebar.parent('.sidebar').width()

        // add the stuck class, remove the unstuck class
        stickySidebar.addClass('stuck').removeClass('unstuck');

        // change the inline styles
        stickySidebar.css({
            'position': 'fixed',
            'top': '-' + win.height() + 'px',
            'width': width + 'px'
        }).animate({top: menuHeight + adminbarHeight + 20 + 'px'}, 250);
    }

    function unstick() {
        // add the unstuck class, remove the stuck class
        stickySidebar.removeClass('stuck').addClass('unstuck');

        stickySidebar.animate({top: '-' + win.height() + 'px'}, 100, () => {
            stickySidebar.removeAttr('style');
            if (dfTop) {
                df.removeClass('d-lg-block')
            }
        });
    }

}(jQuery, window, ccGlobals));