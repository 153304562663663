(function ($, window) {

    const win = $(window);

    let startTime

    win.on("load", function () {

        // start a timer on load
        startTime = new Date()

        $(document.querySelectorAll('.mainmenu__he')).each(function (i, heElement) {

            // console.log(heElement)

            $(heElement).on('click', null, null, (event) => {

                // Extract the href attribute value
                const hrefValue = heElement.getAttribute('href');

                // Create a URL object
                const url = new URL(hrefValue);

                // Create a URLSearchParams object from the query string
                const params = new URLSearchParams(url.search);

                sendGaEvent(heElement, event, {
                    'he_classes': 'mainmenu__he',
                    'he_widget': 'menu btn',
                    'he_degree': params.get('degree') ?? '',
                    'he_category': params.get('category') ?? '',
                    'he_subject': params.get('subject') ?? '',
                })

            })

        })

        // put a click handler on each qdf as they appear
        $(document.getElementsByTagName('he-qdf')).each(function (i, heElement) {

            waitForElm('button', this).then((btn) => {

                // console.log('QDF is ready');

                $(btn).on('click', null, null, (event) => {

                    sendGaEvent(heElement, event, {
                        'he_classes': heElement.closest('.qdf-wrapper').classList.value,
                        'he_widget': 'quick degree finder',
                    })

                })
            });
        })

        // put a click handler on each el as they appear
        $(document.getElementsByTagName('he-editorial-listings')).each(function (i, heElement) {

            waitForElm('.el-button', this).then(() => {

                // console.log('EL is ready');

                let clickables = this.shadowRoot.querySelectorAll('.cursor-pointer, button, a')

                $(clickables).each((i, element) => {

                    let schoolProgram = {}

                    const schoolWrapper = element.closest('.cursor-pointer')

                    if (schoolWrapper) {

                        const school = schoolWrapper.querySelector('.school').innerText,
                            program = schoolWrapper.querySelector('.program').innerText

                        schoolProgram = {
                            'he_school': `${school} | ${program}`
                        }
                    }

                    $(element).on('click', null, null, (event) => {

                        sendGaEvent(heElement, event, {
                            ...{
                                'he_classes': heElement.closest('.he-el-wrapper').classList.value,
                                'he_widget': 'editorial listing',
                            },
                            ...schoolProgram
                        })

                    })
                })

            });

        })

    });

    function waitForElm(selector, wrapper, isShadow = true) {

        // console.log('waiting for: ', selector);

        return new Promise(resolve => {

            let find = isShadow ? wrapper.shadowRoot.querySelector(selector) : wrapper.querySelector(selector)

            // console.log('find: ', find);

            if (find) {
                return resolve(find);
            }

            const observer = new MutationObserver(() => {

                find = isShadow ? wrapper.shadowRoot.querySelector(selector) : wrapper.querySelector(selector)
                // console.log('observe find: ', find);
                if (find) {
                    resolve(find);
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }

    function sendGaEvent(heElement, event, def, debugView = false) {

        const target = event.target,
            debug = debugView ? {'debug_mode': true} : {},
            finalDefs = {
                ...{
                    'event': 'he_click',
                    'he_degree': heElement.getAttribute('degree') ?? '',
                    'he_category': heElement.getAttribute('category') ?? '',
                    'he_subject': heElement.getAttribute('subject') ?? '',
                    'he_sec_to_click': (new Date() - startTime) / 1000,
                    'he_scrolled_percentage': getScrollPercentage(),
                    'he_url': window.location.href,
                    'he_clicked_tag': target.tagName,
                    'he_clicked_text': target.innerText
                },
                ...def,
                ...debug
            }


        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(finalDefs);
        // console.log('window.dataLayer: ', window.dataLayer)

        // gtag("event", "he_click", finalDefs)
    }

    function getScrollPercentage() {

        const
            h = document.documentElement,
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight',
            p = (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100

        return Math.round(Math.min(Math.max(p, 0), 100))

    }

}(jQuery, window));