(function ($, window, document, ccg) {

    function initDegreeFinderTab() {

        const
            $window = $(window),
            $document = $(document),
            constants = {
                wiggleRoom: 100,
                tabHeight: 50,
                tabTopUnstuck: -150, // (tabHeight + wiggleRoom) * -1
                tabButtonSpace: 12,
                dfTopStuck: 25
            },
            state = {
                $tab: undefined,
                $df: undefined,
                $mainMenu: undefined,
                contentWidth: undefined,
                dfTopUnstuck: undefined,
                topFold: undefined,
                bottomFoldOffset: undefined,
                maxWidth: undefined,
                mainMenuHeight: undefined,
                adminBarHeight: undefined,
                windowWidth: undefined,
                namespace: undefined,
                zIndex: undefined
            }

        $window.on('cc:mainmenu', function () {
            initTab().then(result => {
                if (result) {
                    bindEvents();
                }
            })
        });

        async function initTab() {
            if (!initRequiredElements()) {
                return false;
            }

            createTab();
            await measureElements();
            setHiddenStyles();
            await checkScroll();

            return true;
        }

        function initRequiredElements() {
            // Find degree finder and main menu
            state.$df = $('.qdf-float-tab');
            state.$mainMenu = $('.mainmenu');

            // Check if both elements exist
            if (state.$df.length !== 1 || state.$mainMenu.length !== 1) {
                return false;
            }

            const winWidth = window.innerWidth;
            const breakpoint = ccg.getMenuBreakpoint(state.$mainMenu, winWidth);

            // Set namespace and zIndex based on window width
            if (winWidth < breakpoint) {
                state.namespace = '.dfTabMobile';
                state.zIndex = 999999999;
            } else {
                state.namespace = '.dfTabDesk';
                state.zIndex = 500;

                // Check for sidebar on desktop
                if ($('.sidebar').length > 0) {
                    return false;
                }
            }

            return true;
        }

        function createTab() {
            // Remove existing tab if present
            $('#df-tab').remove();

            // Create and insert new tab
            state.$tab = $('<div>', {
                id: 'df-tab',
                class: 'unstuck'
            }).insertAfter(state.$mainMenu);
        }

        async function measureElements() {
            // Cache jQuery objects
            const $admin = $('#wpadminbar');
            const $content = $('.cc-content > .container-lg');
            const $qdfTop = $('.qdf-top');
            const $hero = $('.hero');
            const $dfBottom = $('.qdf-bottom');
            const $footer = $('footer');

            // Calculate admin bar height
            state.adminBarHeight = ($admin.outerHeight() ?? 0) + parseInt($admin.css('top') || '0', 10);

            // Measure main menu and content
            state.mainMenuHeight = state.$mainMenu.outerHeight();
            state.contentWidth = $content.width();

            // Get degree finder height and calculate top unstuck position
            const dfHeight = await getDegreeFinderHeight();
            state.dfTopUnstuck = (dfHeight + constants.wiggleRoom) * -1;

            // Determine top fold
            const $topElement = $qdfTop.length ? $qdfTop : $hero;
            state.topFold = $topElement.length
                ? $topElement.offset().top + $topElement.outerHeight() + state.mainMenuHeight + constants.wiggleRoom
                : 300;

            // Calculate bottom fold offset
            state.bottomFoldOffset = $dfBottom.length && $footer.length
                ? $footer.height() + $dfBottom.height() + $window.height()
                : 0;

            // Get max width and current window width
            state.maxWidth = parseFloat(state.$df.find('.he-qdf').css('max-width'));
            state.windowWidth = $window.width();
        }

        function getDegreeFinderHeight() {
            return new Promise(resolve => {
                const $df = state.$df;
                const prevStyles = {
                    visibility: $df.css('visibility'),
                    display: $df.css('display'),
                };

                $df.removeClass('d-none').css({
                    visibility: 'hidden',
                    display: 'block',
                    width: state.contentWidth + 'px',
                    margin: 0
                });

                // Use requestAnimationFrame to ensure DOM update before measuring
                requestAnimationFrame(() => {
                    const height = $df.outerHeight();

                    $df.addClass('d-none').css(prevStyles);

                    resolve(height);
                });
            });
        }


        async function checkScroll() {
            const pageTop = $window.scrollTop();
            const bottomFold = $document.height() - state.bottomFoldOffset;

            if (state.$tab.hasClass('stuck')) {
                if (pageTop < state.topFold || pageTop > bottomFold) {
                    await Promise.all([hideDegreeFinder(), hideTab()]);
                    setHiddenStyles();
                }
            } else if (state.$tab.hasClass('unstuck') && pageTop >= state.topFold && pageTop <= bottomFold) {
                stickTab();
            }
        }

        function stickDegreeFinder() {
            const top = constants.dfTopStuck + state.adminBarHeight +
                (state.namespace === '.dfTabDesk' ? state.mainMenuHeight : 0);

            state.$df
                .removeClass('unstuck d-none')
                .addClass('stuck')
                .animate({top: `${top}px`}, 250);
        }

        function tabToButton() {
            const isDesk = state.namespace === '.dfTabDesk';
            const windowHalfWidth = $window.width() * 0.5;
            const tabHalfWidth = constants.tabHeight * 0.5;
            const maxWidthHalf = state.maxWidth * 0.5;

            const commonStyles = {
                'background-image': 'none',
                'color': '#1D3F75',
                'width': `${constants.tabHeight}px`,
                'opacity': 0.8
            };

            const specificStyles = isDesk ? {
                'left': `${state.maxWidth - constants.tabButtonSpace}px`,
                'margin': '0',
                'top': `${state.adminBarHeight + state.mainMenuHeight + constants.tabButtonSpace}px`
            } : {
                'left': 'unset',
                'margin-right': `${windowHalfWidth - maxWidthHalf - tabHalfWidth + constants.tabButtonSpace}px`,
                'top': `${state.adminBarHeight + constants.tabButtonSpace}px`
            };

            const finalStyles = {...commonStyles, ...specificStyles};

            state.$tab
                .css(finalStyles)
                .animate(finalStyles, 200)
                .queue(function (next) {
                    $(this).animate({'border-radius': '50%'});
                    next();
                })
                .text('X')
                .addClass('xbutton');
        }

        function hideDegreeFinder() {
            return new Promise(resolve => {
                state.$df.animate(
                    {top: state.dfTopUnstuck},
                    {
                        duration: 250,
                        complete: function () {
                            $(this)
                                .attr('style', '')
                                .addClass('d-none unstuck')
                                .removeClass('stuck');
                            resolve();
                        }
                    }
                );
            });
        }

        function hideTab() {
            return new Promise(resolve => {
                state.$tab.animate(
                    {top: constants.tabTopUnstuck},
                    {
                        duration: 250,
                        complete: function () {
                            $(this)
                                .removeClass('stuck xbutton')
                                .addClass('unstuck');
                            resolve();
                        }
                    }
                );
            });
        }

        function adjustOffsets() {
            if (!state.$tab.hasClass('stuck')) {
                return;
            }

            stickTab();

            if (state.$df.hasClass('stuck')) {
                stickDegreeFinder();
            }
        }

        function setHiddenStyles() {
            const isDesk = state.namespace === '.dfTabDesk';

            const dfCommonStyles = {
                position: 'fixed',
                'z-index': state.zIndex,
                margin: 0,
                width: `${state.contentWidth}px`,
                top: `${state.dfTopUnstuck}px`
            };

            const dfSpecificStyles = isDesk ? {
                'max-width': `${state.maxWidth}px`,
                left: `${constants.dfTopStuck}px`
            } : {};

            const tabCommonStyles = {
                height: `${constants.tabHeight}px`,
                width: `${state.contentWidth}px`,
                'max-width': `${state.maxWidth}px`,
                position: 'fixed',
                top: `${constants.tabTopUnstuck}px`,
                'z-index': state.zIndex + 1,
                margin: '0',
                background: 'white',
                color: 'white',
                'font-weight': 'bold',
                'font-family': 'Quicksand',
                'text-align': 'center',
                'line-height': '1em',
                cursor: 'pointer',
                border: '1px solid #EDEDEEFF',
                'border-top': 'none',
                'text-transform': 'uppercase',
                'box-shadow': '0 4px 4px rgba(0, 0, 0, .2)',
                'border-radius': '0 0 6px 6px',
                'background-image': 'linear-gradient(to top, #1F4F54 0, #1F4F54 100%)',
                'text-shadow': '0 1px 1px rgba(0, 0, 0, .5)',
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                opacity: 1
            };

            const tabSpecificStyles = isDesk ? {
                left: `${constants.dfTopStuck}px`
            } : {
                left: 0,
                right: 0,
                margin: '0 auto'
            };

            const applyStyles = ($element, commonStyles, specificStyles) => {
                $element
                    .attr('style', '')
                    .css({...commonStyles, ...specificStyles});
            };

            applyStyles(state.$df.addClass('unstuck d-none').removeClass('stuck'), dfCommonStyles, dfSpecificStyles);
            applyStyles(
                state.$tab
                    .text(state.$df.find('.qdf-heading').text())
                    .addClass('unstuck')
                    .removeClass('stuck'),
                tabCommonStyles,
                tabSpecificStyles
            );
        }

        function stickTab() {
            const isDesk = state.namespace === '.dfTabDesk';
            const topPosition = isDesk
                ? state.adminBarHeight + state.mainMenuHeight + (state.$tab.hasClass('xbutton') ? constants.tabButtonSpace : 0)
                : state.adminBarHeight;

            state.$tab
                .removeClass('unstuck')
                .addClass('stuck')
                .animate({top: `${topPosition}px`}, 250);
        }

        function bindEvents() {
            $window.on(`scroll${state.namespace}`, ccg.throttle(checkScroll, 600));

            $window.on(`resize${state.namespace}`, ccg.debounce(() => {
                if (Math.abs($window.width() - state.windowWidth) > 100) {
                    state.$df.addClass('d-none unstuck').removeClass('stuck');
                    $window.off(state.namespace).trigger('cc:mainmenu');
                }
            }, 500));

            state.$tab.on(`click${state.namespace}`, async () => {
                if (state.$df.hasClass('unstuck')) {
                    stickDegreeFinder();
                    tabToButton();
                } else if (state.$df.hasClass('stuck')) {
                    await hideDegreeFinder();
                    await hideTab();
                    state.$df.remove();
                    state.$tab.remove();
                }
            });

            if (state.namespace === '.dfTabDesk') {
                $window.on(`cc:mainmenu:hide${state.namespace}`, () => {
                    state.mainMenuHeight = 0;
                    adjustOffsets();
                });

                $window.on(`cc:mainmenu:show${state.namespace}`, () => {
                    state.mainMenuHeight = state.$mainMenu.outerHeight();
                    adjustOffsets();
                });
            }
        }
    }

    // Initialize the functionality
    initDegreeFinderTab();

})(jQuery, window, document, ccGlobals);
